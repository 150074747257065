import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

export interface Notification {
   message: string;
   target: NotificationTarget;
   buttonText?: string;
   notificationType?: NotificationType;
   isDismissible?: boolean;
}

export enum NotificationType {
   Success = "success",
   Error = "error",
   Warning = "warning",
   Info = "info",
   Default = "default",
}

export enum NotificationTarget {
   Online = "online",
   Offline = "offline",
}

@Injectable({ providedIn: "root" })
export class NotificationService {
   private readonly notificationSubject = new BehaviorSubject<Notification | null>(null);
   public notifications$ = this.notificationSubject.asObservable();

   public showNotification(notification: Notification): void {
      this.notificationSubject.next(notification);
   }

   public clear(): void {
      this.notificationSubject.next(null);
   }
}
